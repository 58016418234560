function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { createElement, Fragment } from 'react';
import { Logo } from 'apps/acp/packages/logo-locator';
import { useTheme } from './theme';
import { AppBarContainer, SectionCenter } from './app-bar.ccm.css';
import { createOpenWithInAppBrowserAnchor } from 'apps/acp/packages/cordova-in-app-browser';
import { EnvChooser, useEnvChooserContext } from 'apps/acp/packages/env-chooser';
import { isCordova } from 'packages/platform-detector';
export const AppBar = ({
  marketingSiteUrl,
  inverted,
  mode,
  statusBarBackgroundColor,
  AppBarContainerWrapper
}) => {
  const {
    appBarWhiteBackgroundColor,
    appBarBorderBottomColor
  } = useTheme();
  const homeAnchor = createOpenWithInAppBrowserAnchor(marketingSiteUrl || '');
  const {
    isEnvChooserVisible
  } = useEnvChooserContext();
  const AppBarWrapper = AppBarContainerWrapper ? AppBarContainerWrapper.as(AppBarContainer.div) : AppBarContainer.div;
  return createElement(Fragment, null, isEnvChooserVisible && mode !== 'production' && isCordova() && createElement("div", {
    style: {
      padding: '8px'
    }
  }, createElement(EnvChooser, null)), createElement(AppBarWrapper, {
    $backgroundColor: inverted ? appBarWhiteBackgroundColor : statusBarBackgroundColor,
    $borderBottomColor: appBarBorderBottomColor
  }, createElement(SectionCenter.div, null, createElement("a", _extends({}, homeAnchor, {
    "aria-label": "Marketing site link redirection for logo locator"
  }), createElement(Logo, null)))));
};