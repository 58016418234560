function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { createElement, lazy, useEffect } from 'react';
import { SuspenseHandler } from 'apps/acp/packages/react-suspense-handler';
import { AppBar } from './app-bar';
import { useTheme } from './theme';
import { LayoutBody, StyledLayout } from './unauthed-layout.ccm.css';
import { LogoProvider } from 'apps/acp/packages/logo-locator';
import { AcquireNewCustomerContext } from 'apps/acp/packages/acquire-new-customer';
import { useBehaviorSubject } from 'packages/behavior-subject/react';
import { PermissionProvider } from './permissions';
import { ConnectProviderToSubscription } from 'apps/acp/packages/connect-provider-to-subscription';
import { EnvChooserProvider } from 'apps/acp/packages/env-chooser';
const FooterContent = {
  default: lazy(() => import('./footer/default-footer')),
  'paypal-login': lazy(() => import('./footer/paypal-login-footer'))
};

const PermissionProviderAdapter = ({
  children,
  value
}) => createElement(PermissionProvider, {
  permissionResolver: value
}, children);

const InternalLayout = ({
  logoLocator,
  AcquireNewCustomer,
  children,
  appBarInverted,
  statusBarPlugin,
  statusBarBackgroundColor,
  statusBarForegroundColor,
  variantId,
  version,
  mode,
  copyrightStatement,
  loyaltyProgramDetailDisclaimer,
  fasterFundingDisclaimer,
  paybackRewardsDisclaimer,
  cardDisclaimer,
  importantPatriotActDisclosure,
  programType,
  showRegisterLink,
  showMarketingSiteLink,
  marketingSiteUrl,
  marketingSiteLinkText,
  contactLinks,
  activateCardLinks,
  registerLinks,
  locationFinderLinks,
  logoutLinks,
  loginLinks,
  showReloadLocationLink,
  gaCategory,
  gaLabel,
  showFooterLinks,
  showFooter,
  atmFinderLinks,
  showFreeAtmFinderLink,
  showAcquisitionLink,
  openAccountFooterContent,
  permissionsResolverSubscribe,
  clearSessionStorage,
  styledLayoutColor,
  layoutBodyColor,
  AppBarContainerWrapper,
  customizedLoginPage = 'default'
}) => {
  const acquireNewCustomerInstance = useBehaviorSubject(AcquireNewCustomer);
  const {
    layoutGreyBackgroundColor
  } = useTheme();
  const Footer = FooterContent[customizedLoginPage];
  useEffect(() => {
    statusBarPlugin.setStatusBarColor(statusBarBackgroundColor, statusBarForegroundColor);
  }, [statusBarPlugin, statusBarBackgroundColor, statusBarForegroundColor]);
  return createElement(EnvChooserProvider, null, createElement(AcquireNewCustomerContext.Provider, {
    value: acquireNewCustomerInstance
  }, createElement(ConnectProviderToSubscription, {
    subscribe: permissionsResolverSubscribe,
    Provider: PermissionProviderAdapter
  }, createElement(LogoProvider, {
    logoLocator: logoLocator
  }, createElement(StyledLayout.div, {
    $backgroundColor: styledLayoutColor ? styledLayoutColor : layoutGreyBackgroundColor,
    "data-testid": variantId
  }, createElement(SuspenseHandler, null, createElement(AppBar, {
    marketingSiteUrl: marketingSiteUrl,
    inverted: appBarInverted,
    mode: mode,
    statusBarBackgroundColor: statusBarBackgroundColor,
    AppBarContainerWrapper: AppBarContainerWrapper
  })), createElement(LayoutBody.div, {
    $backgroundColor: layoutBodyColor ? layoutBodyColor : layoutGreyBackgroundColor
  }, createElement(SuspenseHandler, null, children)), createElement(Footer, {
    programType,
    showRegisterLink,
    showMarketingSiteLink,
    marketingSiteUrl,
    marketingSiteLinkText,
    contactLinks,
    activateCardLinks,
    registerLinks,
    locationFinderLinks,
    logoutLinks,
    loginLinks,
    atmFinderLinks,
    showAcquisitionLink,
    showFreeAtmFinderLink,
    openAccountFooterContent,
    showReloadLocationLink,
    gaCategory,
    gaLabel,
    clearSessionStorage,
    version,
    copyrightStatement,
    importantPatriotActDisclosure,
    loyaltyProgramDetailDisclaimer,
    fasterFundingDisclaimer,
    paybackRewardsDisclaimer,
    cardDisclaimer,
    showFooterLinks,
    showFooter,
    customizedFooterPage: customizedLoginPage
  }))))));
};

export const createUnauthedLayout = dependencies => props => createElement(InternalLayout, _extends({}, dependencies, props));